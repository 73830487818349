<template>
  <div class="terms-conditions">
    <h2>Terms of use</h2>

    <p>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS RELATING TO YOUR USE OF OUR APPLICATION CAREFULLY. By using our Application, you agree to these Terms and Conditions of Use ("Terms & Conditions"). We reserve the right, in our sole discretion, to change, modify, add or remove portions of these Terms & Conditions at any time. You should check these Terms & Conditions periodically for changes. By using this Application after we post any changes to these Terms & Conditions, you agree to accept those changes, whether or not you have reviewed them. If you do not agree to these Terms & Conditions, you should not use our Application and, if applicable, you should arrange to cancel your user account(s).</p>

    <h3>1. Scope of Terms & Conditions</h3>

    <p>
      Unless we indicate otherwise, these Terms & Conditions apply to your use of the Application (whether, through websites <a
        href="http://www.getactivehub.net"
        target="_blank"
      >http://www.getactivehub.net</a> or any applications (iOS and Android), software (including without limitation the API) or services made available through the Application] which are owned or operated by Ember Technology Ltd (using, “Ember” "we," "us," or "our), on behalf of HSBC and its authorised agency CSM Sport and Entertainment LLP (“CSM”).
    </p>

    <h3>2. Terms & Conditions - In General</h3>

    <p>By using this Application, you agree to be legally bound and to abide by these Terms & Conditions, just as if you had signed this agreement. If you do not comply with these Terms & Conditions at any time, we reserve the right, if applicable, to terminate your password, user account, and/or access to this Application (or any part thereof). In our sole discretion and without prior notice or liability, we may discontinue, modify or alter any aspect of the Application, including, but not limited to, (i) restricting the time the Application is available, (ii) restricting the amount of use permitted, and (iii) restricting or terminating any user's right to use the Application. You agree that any termination or cancellation of your access to, or use of, the Application may be effected without prior notice. If you do not abide by the provisions of these Terms & Conditions, except as we may otherwise provide from time to time, you agree that we may immediately deactivate or delete your user account and all related information and files in your user account and/or bar any further access to such information and/or files, or our Application. Further, you agree that we shall not be liable to you or any third-party for any termination or cancellation of your access to, or use of, our Applications.</p>

    <p>From time to time, we may supplement these Terms & Conditions with additional terms and conditions pertaining to specific content, activities, events, rewards or member offers ("Additional Terms"). Such Additional Terms may be placed on the Application to be viewed in connection with the specific content, activities, offers, features or events and shall be identified as such. You understand and agree that such Additional Terms are hereby incorporated by reference into these Terms & Conditions.</p>

    <h3>3. Cancellation and Termination by you</h3>

    <p>You are solely responsible for cancelling your account (without limiting HSBC, Ember or CSM ‘s other rights to suspend or terminate your account). Should you wish to unsubscribe from your account, the marketing material, make any comments in general or have any questions, please send an email to <a href="mailto:getactivehub@hsbc.com">getactivehub@hsbc.com</a>.</p>

    <p>All of your Content will be deleted from the Application 90 days after the date of cancellation or termination of your account. This Content cannot be recovered.</p>

    <h3>4. Restrictions on Use of Materials</h3>

    <p>Unless otherwise specified, we grant you a non-exclusive, non-transferable, limited right to access, use and display this Application and the material provided hereon for your personal, non-commercial use, provided that you comply fully with the provisions of these Terms & Conditions. You acknowledge that this Application contains information, software, photos, video, text, graphics, music, sounds, questions, creative suggestions, messages, comments, feedback, ideas, recipes, notes, drawings, articles and other materials (collectively, "Content") that are protected by copyrights, data base rights, patents, trademarks, trade secrets and/or other proprietary rights, and that these rights are valid and protected in all forms, media and technologies existing now or hereafter developed. All Content is copyrighted under applicable copyright laws, including United Kingdom, Irish and European Union copyright laws (and, if applicable, similar laws in other jurisdictions), and we own a copyright in the selection, coordination, arrangement and enhancement of such Content. Unless otherwise agreed, you may not modify, publish, transmit, distribute, perform, participate in the transfer or sale, create derivative works of, or in any way exploit, any of the Content, in whole or in part. When Content is downloaded to your computer, you do not obtain any ownership interest in such Content. Modification of the Content or use of the Content for any other purpose, including, but not limited to, use of any Content in printed form or on any other website or networked computer environment is strictly prohibited unless you receive our prior written consent.</p>

    <h3>5. Becoming a Community User</h3>

    <p>There is no cost to become a user of our Application. To become a user you must register in accordance with instructions provided.</p>

    <p>If you elect to become a user of our Application, you agree to provide us with true, accurate and complete information about yourself (" User Data"), and to use reasonable efforts to maintain and promptly update the User Data and any other information you provide to us in order to keep it accurate. You also consent to your User Data being used in accordance with our Privacy Policy. Without limiting any other provision of these Terms & Conditions, if you provide any information that is untrue, inaccurate, or incomplete, or we have reasonable grounds to suspect that such is the case, we reserve the right to request that you correct the User Data and ultimately suspend or terminate your account. You agree not to assign, transfer or sublicense your rights as a user of this Application. You further agree not to register for more than one account, create an account on behalf of someone else, or create a false or misleading identity on this Application.</p>

    <p>If your registration is revoked for any reason, you agree not to register or subscribe again with our Application using another user name or through any other means. If we have reason to suspect, in our sole discretion, that your account has previously been terminated, we reserve the right to terminate any new accounts you have registered without any notice to you, or to exercise any other remedies available to us under these Terms & Conditions of Use or by law.</p>

    <h3>6. Submissions</h3>

    <p>If, you send, email, post or otherwise transmit to us or this Application any Content (collectively, the "Submissions"),you grant us and our successors and assigns a royalty-free, perpetual, irrevocable, non-exclusive right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, communicate to the public, perform and display any Submissions (in whole or in part) worldwide and/or to incorporate the Submissions in other works (including usage and performance reports) in any form, media, or technology now known or later developed, for the full term of any copyrights, trademarks and other intellectual and proprietary rights (collectively, the "Rights") that may exist in such Submissions ,for the purpose intended within the App and for any reasonable reporting, communications and promotional purposes associated with the App.</p>
    <p>You warrant that: (i) you own or otherwise control all of the rights in or to your Submissions including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions;</p>
    <p>(ii) to the extent that you are not the exclusive holder of all Rights in a Submission, any third party holder of any Rights, including in such Submissions, has granted to you the right to grant the license stated above; and (iii) that each person depicted in any images, photos and/or videos contained in your Submission (if any) has consented to the use of such images, photos and/or videos in accordance with the above licence.</p>
    <p>You further acknowledge that we and our successors and assigns shall be entitled to unrestricted use of the Submissions for any purpose whatsoever, commercial or otherwise, without compensation to the provider of the Submissions.</p>

    <h3>7. Content & Conduct</h3>

    <p>You acknowledge that all Content posted, emailed, or otherwise transmitted to or on this Application, whether posted at our request or voluntarily, and whether publicly posted or privately transmitted (collectively, the "Postings"), are the sole responsibility of the person who made such Postings. This means that you are entirely responsible for all Postings that you post, to this Application. We do not control the Postings posted, on our Application by others and, as such, we do not guarantee the accuracy, integrity or quality of such Postings. Although we have adopted community standards and conduct guidelines for the users of our Application (as described below), you understand that by using this Application, you may be exposed to Postings that are offensive or objectionable. Under no circumstances will HSBC, CSM or Ember be liable in any way for any Postings (other than for Content developed by HSBC, CSM or Ember), including, but not limited to, for any errors or omissions in any Postings, or for any loss or damage of any kind incurred as a result of the use of any Postings posted, emailed or otherwise transmitted to or through this Application</p>

    <p>You agree not to use this Application to:</p>

    <ol type="a">
      <li>Upload, post, email or otherwise transmit any Postings or other materials that are unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libellous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable (in our sole discretion);</li>
      <li>Harm minors in any way, or solicit or otherwise attempt to gain any information from a minor;</li>
      <li>Impersonate any person or entity, including, but not limited to, any user of this Application, a director, officer, employee, shareholder, agent or representative of any your employer or any partners, affiliates or contractors associated with the provision of the Application.</li>
      <li>Forge headers or otherwise manipulate identifiers in order to disguise the origin of any Postings or other materials transmitted to or through this Application;</li>
      <li>Upload, post, email or otherwise transmit any Postings or other materials that are not your own, or that you do not have a right to upload, post, email or otherwise transmit under any law or under contractual or fiduciary relationships (such as insider information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);</li>
      <li>Upload or post any Postings or other materials that infringe upon any patent, trademark, trade secret, copyright, right of privacy or publicity or other proprietary rights of any party;</li>
      <li>Upload or post any unsolicited or unauthorised advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of commercial solicitation, except in those areas, if any, that are specifically designated for such purpose.</li>
      <li>Upload or post any Postings or other materials that contain software viruses, or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
      <li>Disrupt the normal flow of dialogue, or otherwise act in a manner that negatively affects or otherwise diminishes the quality of another user’s experience of this Application;</li>
      <li>Interfere with or disrupt this Application or servers or networks connected to this Application, or disobey any requirements, procedures, policies or regulations of networks connected to the Application;</li>
      <li>Intentionally or unintentionally violate any applicable local, state, national or international law, including, but not limited to, any regulations having the force of law;</li>
      <li>"Stalk" or otherwise harass another user or employee of this Application;</li>
      <li>Solicit, collect or post personal data or attempt to solicit, collect or post personal data about other users of the Application (including user names or passwords) or about any other third party; or</li>
      <li>Access or attempt to access another user’s account without his or her consent</li>
    </ol>

    <p>Your privilege to use this Application depends on your compliance with the community standards and conduct guidelines set forth above. We may revoke your privileges to use all or a portion of this Application and/or take any other appropriate measures to enforce these community standards and conduct guidelines if violations are brought to our attention. </p>

    <p>We reserve the right to monitor some, all, or no areas of this Application for adherence to the community standards and conduct guidelines set forth above or for any other purpose. You acknowledge that by providing you with the ability to distribute Postings, we are acting as a passive conduit for such distribution and HSBC, CSM and Eber  are not undertaking any obligation or liability relating to any Postings, nor do HSBC, CSM or Ember endorse any such Postings. Although we reserve the right to remove, without notice, any Posting for any reason, we generally don’t review Content prior to the Content’s posting and we have no obligation to delete Postings that you may find objectionable or offensive. We are not responsible for maintaining a copy of any material we remove from our Application, and HSBC, CSM and Ember are not liable for any loss you incur in the event that Content you post or transmit to our Application is removed.</p>

    <h3>8. Children</h3>

    <p>This Application is not for use by children and is not directed to children. This Application is for use and access by individuals who are eighteen (18) years or older.</p>

    <h3>9. Links</h3>

    <p>We may provide, or third parties may provide, links to other worldwide websites or resources. You acknowledge and agree that HSBC, CSM and Ember are not responsible for the availability of such external sites or resources, and do not endorse (and are not responsible or liable for) any content, advertising, products, or other materials on or available from such websites or resources. You further acknowledge and agree that, under no circumstances, will HSBC, CSM or Ember be held responsible or liable, directly or indirectly, for any loss or damage that is caused or alleged to have been caused to you in connection with your use of, or reliance on, any content, advertisements, products or other resources available on any other website (regardless of whether we directly or indirectly link to such content, advertisements, products or other resources). You should direct any concerns with respect to any other website to that website's administrator or webmaster.</p>

    <h3>10. Password and User Account Security.</h3>

    <p>If you are a user of this Application, you are entirely responsible for maintaining the confidentiality of your password and user account information. You agree to notify us immediately in the event of any known or suspected unauthorised use of your user account, or any known or suspected breach of security, including loss, theft, or unauthorised disclosure of your or anyone else's password. You are entirely responsible for any and all activities which occur under your user account. You agree to immediately notify us of any unauthorised use of your user account or any other breach of security known to you. Such notifications should be sent to getactivehub@hsbc.com</p>

    <h3>11. Health Disclaimer</h3>

    <p>This Application provides physical and mental well-being content and challenges which are intended only to assist users that certify that they are healthy and that their physical condition allows them to perform physical activity. Nothing contained in this Application should be construed as medical advice or diagnosis.</p>

    <p>If you are any doubt regarding the suitability of any content and challenges for you to participate in, you are urged and advised to seek medical advice before beginning any physical activity. This Application is intended for use only by healthy adult individuals. The Application is not intended for use by minors, pregnant women, or individuals with any type of health condition. Such individuals are specifically warned to seek professional medical advice prior to initiating any form of physical activity.</p>

    <h3>12. Disclaimers of Warranties</h3>

    <p>PLEASE NOTE THE FOLLOWING IMPORTANT DISCLAIMERS OF WARRANTIES:</p>

    <p>THE PRODUCTS, OFFERINGS, CONTENT AND MATERIALS ON THIS APPLICATION ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. HSBC, CSM and EMBER  DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY AND FITNESS FOR A PARTICULAR PURPOSE, COMPATIBILITY, SECURITY, OR ACCURACY. IF YOU ARE A RESIDENT OF IRELAND, SECTION 39 OF THE SALE OF GOODS AND SUPPLY OF SERVICES ACT, 1980 IS HEREBY EXCLUDED.</p>

    <p>NEITHER HSBC, EMBER, CSM, ANY OF OUR AFFILIATES, NOR ANY OF OUR OR THEIR RESPECTIVE LICENSORS, LICENSEES, SERVICE PROVIDERS OR SUPPLIERS WARRANT THAT THIS APPLICATION OR ANY FUNCTION CONTAINED IN THIS APPLICATION OR ANY ASSOCIATED WEBSITES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS APPLICATION OR ANY ASSOCIATED WEBSITES OR THE SERVERS THAT MAKE THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>

    <p>ANY PRODUCT, OFFERING, CONTENT AND MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THIS APPLICATION IS DONE AT YOUR SOLE RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH PRODUCT, OFFERING, CONTENT OR MATERIAL.</p>

    <p>NEITHER HSBC, EMBER, CSM, ANY OF OUR AFFILIATES, NOR ANY OF OUR OR THEIR RESPECTIVE LICENSORS, LICENSEES, SERVICE PROVIDERS OR SUPPLIERS WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE PRODUCTS, OFFERINGS, CONTENT AND MATERIALS IN THIS WEBSITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE.</p>

    <p>FURTHER, PLEASE NOTE THAT NO ADVICE OR INFORMATION, OBTAINED BY YOU FROM OUR PERSONNEL OR THROUGH THIS APPLICATION SHALL CREATE ANY WARRANTY NOT EXPRESSLY PROVIDED FOR IN THESE TERMS & CONDITIONS.</p>

    <p>THESE DISCLAIMERS APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.</p>

    <h3>13. Limitation of Liability</h3>

    <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT HSBC, EMBER, CSM AND OUR AFFILIATES SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES, OR ANY OTHER DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF, OR RESULTING FROM, (A) THE USE OR THE INABILITY TO USE THE APPLICATION; (B) THE USE OF ANY CONTENT OR OTHER MATERIAL ON THE APPLICATION OR ANY WEBSITE OR WEBSITES LINKED TO THE APPLICATION, (C) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THIS APPLICATION; (D) UNAUTHORISED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (E) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE APPLICATION; OR (F) ANY OTHER MATTER RELATING TO THE APPLICATION. SAVE IN RESPECT OF PERSONAL INJURY OR DEATH DUE TO THE NEGLIGENCE OF CSM, IN NO EVENT SHALL THE TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE) SHALL NOT EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE APPLICATION. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE APPLICATION, OR WITH ANY OF THESE TERMS & CONDITIONS, YOUR SOLE AND EXCLUSIVE REMEDY IS THE DISCONTINUATION OF YOUR USE OF THIS APPLICATION. THESE EXCLUSIONS OF LIABILITY APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.</p>

    <h3>14. Indemnification</h3>

    <p>You agree to indemnify, hold harmless and, at our option, defend HSBC, Ember, CSM, and our officers, directors, employees, stockholders, agents and representatives from any and all third party claims, liability, damages and/or costs (including, but not limited to, reasonable legal fees and expenses) arising from your improper use of this Application or our products or offerings, your violation of these Terms & Conditions, or your infringement, or the infringement or use by any other user of your account, of any intellectual property or other right of any person or entity.</p>

    <h3>15. Governing Law and Jurisdiction</h3>

    <p>If you are a resident of the United Kingdom (excluding the Channel Islands), these Terms & Conditions shall be governed by and construed in accordance with the laws of England and Wales, without giving effect to any principles of conflicts of law. You agree that any action at law or in equity arising out of or relating to your use of this Application or these Terms & Conditions shall be filed only in the High Court (England) and you hereby consent and submit to the personal jurisdiction of such court for the purposes of litigating any such action.</p>

    <h3>16. Miscellaneous Terms</h3>

    <p>If any provision of these terms shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these terms and shall not affect the validity and enforceability of any remaining provisions. These Terms & Conditions, together with our Privacy Policy, are the entire agreement between you and us relating to the subject matter herein. These Terms & Conditions may be modified only by our posting of changes to these Terms & Conditions on this Application, or by written agreement of both parties. Each time you access this Application, you will be deemed to have accepted any such changes.</p>

    <p>We may assign our rights and obligations under these Terms & Conditions. These Terms & Conditions will inure to the benefit of our successors, assigns and licensees. The failure of either party to insist upon or enforce the strict performance of the other party with respect to any provision of these Terms & Conditions, or to exercise any right under the Terms & Conditions, will not be construed as a waiver or relinquishment to any extent of such party's right to assert or rely upon any such provision or right in that or any other instance; rather, the same will be and remain in full force and effect.</p>

    <p>Unless specifically provided for in these Terms & Conditions, no third party shall have any right hereunder.</p>
  </div>
</template>

<script>
export default {
  name: 'TermsConditionsPage',
}
</script>
